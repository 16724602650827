import { html, render } from 'es5-lit-html';
import qs from 'qs';

class LoaderFormLMT {
  constructor(product, options) {
    this._product = product;
    this._height = options.height || 500;
    this._buttonColor = options.buttonColor || '000000';
    this._size = options.size || 'medium';

    this._lmt = {};
    this._lmt.id_delivery = options.id_delivery;
    this._lmt.id_feed = options.id_feed;

    this._filters = options.filters || {};
  }

  _buildQuery() {
    const conf = {
      __button_color: this._buttonColor,
      __size: this._size
    };
    const params = qs.parse(window.location.search, { ignoreQueryPrefix: true });

    if (params.id_delivery) {
      conf.__lmt_id_delivery = params.id_delivery;
      delete params.id_delivery;
    } else if (this._lmt.id_delivery) {
      conf.__lmt_id_delivery = this._lmt.id_delivery;
    }

    if (params.id_feed) {
      conf.__lmt_id_feed = params.id_feed;
      delete params.id_feed;
    } else if (this._lmt.id_feed) {
      conf.__lmt_id_feed = this._lmt.id_feed;
    }

    return Object.assign(
      conf,
      params,
      this._filters
    );
  }

  build(domElement) {
    const template = html`<iframe 
src="https://${this._product}.lmtform.com/?${qs.stringify(this._buildQuery())}" 
height="${this._height}px" width="100%" style="border: 0"></iframe>`;
    render(template, domElement);
  }
}

export default LoaderFormLMT;
